<template>
  <div class="bg-white " style="height: 358px">
    <div class="sec_title">
      账号安全
    </div>
    <div v-for="(item, index) in editVal" :key="index" class="sec_card">
      <div class="sec_card_left">
        <div class="img">
          <img :src="item.icon">
          <div class="text">
            <div style="font-size:16px;">{{ item.val1 }}</div>
            <div style="color:#999">{{ item.val2 }}</div>
          </div>
        </div>
      </div>
      <div class="sec_card_right" @click="handleEdit(index)">
        修改
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccountSec',
  data () {
    return {
      editVal: [{
        icon: 'https://store.heytapimage.com/cdo-portal/feedback/202211/07/359877b0b728f5a7c0d41ce1955667dd.png',
        val1: '账号密码',
        val2: '账号信息和登录安全'
      }, {
        icon: 'https://store.heytapimage.com/cdo-portal/feedback/202211/07/4b6966c1699de487811452acb444529b.png',
        val1: '手机绑定',
        val2: '手机安全可以使用供应链或进行安全验证'
      }]

    }
  },
  methods: {
    handleEdit (i) {
      this.$emit('handleGo', i)
      if (i === 0) {
        this.$router.push({
          name: 'editPwd',
          params: {
            id: i
          }
        })
      } else if (i === 1) {
        this.$router.push({
          name: 'EditPhone',
          params: {
            id: i
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sec_title {
  margin: 25px 0 0 50px;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.sec_card {
  width: calc(100% - 300px);
  border-radius: 5px;
  box-shadow: 0 0 15px #e6e6e6;
  margin: 28px auto 0 46px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 20px;

  .sec_card_left {
    .img {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }

      .text {
        margin-left: 35px;

        > div:nth-child(1) {
          font-size: 14px;
          color: #202124;
          font-weight: 700;
        }

        > div:nth-child(2) {
          margin-top: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #202124;
        }
      }
    }
  }

  .sec_card_right {
    cursor: pointer;
    width: 60px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    background-color: $color-primary;
    border-radius: 5px
  }
}

</style>
